import React, { Component } from "react";
import { Route, Redirect, Router, Switch } from "react-router-dom";
import asyncComponent from "./helpers/async-func";
import Layout from "./components/layout";
import { getJsonCookies, getCookie } from '../app/helpers/utility';
import {getSettings}  from "../app/containers/user/service";
import { createLables } from './helpers'

//import Loader from "../app/components/loader";
import {reactLocalStorage} from 'reactjs-localstorage';
let titlePrefix = "Jointly | ";
let maintenanceMode = process.env.REACT_APP_MAINTENANCE;

console.log("M is =>> ", maintenanceMode)

const AdminRoute = ({ component: Component, layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let userData = getCookie()
      return (
        maintenanceMode === 'true' ? 
          <Redirect
            to={{
              pathname: "/maintenance",
              state: { from: props.location }
            }}
          />
        :
        userData && userData.role_id && (userData.role_id === 1 || userData.role_id === 6) ? (
          <Layout settings={layoutSettings}>
            <Component {...props} settings={layoutSettings} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        ))
    }

    }
  />
);
const RestrictedRoute = ({component: Component,layoutSettings = {}, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      maintenanceMode === 'true' ? 
        <Redirect
          to={{
            pathname: "/maintenance",
            state: { from: props.location }
          }}
        />
      :
      getJsonCookies() ? (
        <Layout settings={layoutSettings}>
          <Component {...props} settings={layoutSettings} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const UnRestrictedRoute = ({ component: Component,layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      maintenanceMode === 'true' ? 
        <Redirect
          to={{
            pathname: "/maintenance",
            state: { from: props.location }
          }}
        />
      :
      !getJsonCookies() ? (
        <Component {...props} settings={layoutSettings}  />
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoute = ({ component: Component, hideToolBar = false, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      maintenanceMode === 'true' ? 
        <Redirect
          to={{
            pathname: "/maintenance",
            state: { from: props.location }
          }}
        />
      :
        <Component {...props} hideToolBar={hideToolBar} />
    }
  />
);
const MaintenanceRoute = ({ component: Component, hideToolBar = false, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      maintenanceMode === 'true' ? 
      <Component {...props} hideToolBar={hideToolBar} />
      :
      <Redirect
        to={{
          pathname: "/",
          state: { from: props.location }
        }}
      />
    }
  />
);

export default class extends Component {

  state = {
    //loading: true
  };

  async componentDidMount () {
    await createLables()
  }

  async UNSAFE_componentWillMount() {
    await this.getSetting();
    // await this.getUser();
  }
  // async getUser() {
  //   try {
  //       const user = await getCookies();
  //       await this.setState({ user: JSON.parse(user) });
  //   } catch (e) {
  //     await this.setState({ user: {} });
  //   }
  // }

  async getSetting() {
    try {
      //let URL = reactLocalStorage.getObject('cloudfront');
      //if(Object.keys(URL).length === 0) {
        const settings = await getSettings();
        reactLocalStorage.setObject('cloudfront', settings);
      //}
      //this.setState({loading: false});
    } catch (e) {
      //this.setState({loading: false});
    }
  }
  
  render() {
  const { history } = this.props;
  // const { user } = this.state;

  //const { loading } = this.state;
  //if (loading) return <Loader />;
  return (
    <Router history={history}>
      <Switch>
        <UnRestrictedRoute
          exact
          path={"/"}
          component={asyncComponent(() => import("./containers/jointly-landing"), "Jointly App")}
        />
        <UnRestrictedRoute
          exact
          path={"/login"}
          layoutSettings={{
            title: "Login",
          }}
          component={asyncComponent(() => import("./containers/user/login"), `${titlePrefix}Login`)}
        />
        <UnRestrictedRoute
          exact
          path={"/alexalink"}
          layoutSettings={{
            title: "Alexa Link",
          }}
          component={asyncComponent(() => import("./containers/user/alexa-link"), `${titlePrefix}Alexa Link`)}
        />
        <UnRestrictedRoute
          exact
          path={"/signup"}
          layoutSettings={{
            title: "Signup",
          }}
          component={asyncComponent(() => import("./containers/user/signup"), `${titlePrefix}Signup`)}
        />
        <UnRestrictedRoute
          exact
          path={"/forgot-password"}
          layoutSettings={{
            title: "Forgot password",
          }}
          component={asyncComponent(() => import("./containers/user/forgot"), `${titlePrefix}Forgot password`)}
        />
        <UnRestrictedRoute
          exact
          path={"/magic-signin-link"}
          layoutSettings={{
            title: "magic Link",
          }}
          component={asyncComponent(() => import("./containers/user/magic-link"), `${titlePrefix}Magic Link`)}
        />
        <UnRestrictedRoute
          exact
          path={"/reset-password"}
          component={asyncComponent(() => import("./containers/user/reset"), `${titlePrefix}Reset password`)}
        />
        <PublicRoute
          exact
          path={"/activity-list"}
          component={asyncComponent(() => import("./containers/circles/download-details"), `${titlePrefix}Download`)}
        />
        <PublicRoute
          exact
          path={"/accept-invitation"}
          component={asyncComponent(() => import("./containers/invite"), `${titlePrefix}Invitation`)}
        />
        <PublicRoute
          exact
          path={"/terms-of-service"}
          component={asyncComponent(() => import("./containers/jointly-terms-of-service"), `${titlePrefix}Terms of service`)}
        />
        <PublicRoute
          exact
          path={"/faq"}
          component={asyncComponent(() => import("./containers/jointly-faq"), `${titlePrefix}FAQ`)}
        />
        <PublicRoute
          exact
          path={"/user-guide"}
          component={asyncComponent(() => import("./containers/jointly-user-guide"), `${titlePrefix}User Guide`)}
        />
        <PublicRoute
          exact
          path={"/jointly-video"}
          component={asyncComponent(() => import("./containers/jointly-video"), `${titlePrefix}Video`)}
        />
        
        <PublicRoute
          exact
          path={"/jointly-at-glance"}
          component={asyncComponent(() => import("./containers/jointly-glance"), `${titlePrefix}Glance`)}
        />
        <PublicRoute
          exact
          path={"/privacy-notice"}
          component={asyncComponent(() => import("./containers/jointly-privacy-policy"), `${titlePrefix}Privacy policy`)}
        />
         <PublicRoute
          exact
          path={"/cookies-policy"}
          component={asyncComponent(() => import("./containers/jointly-cookies-policy"), `${titlePrefix}Glance`)}
        />
        <PublicRoute
          exact
          path={"/about"}
          component={asyncComponent(() => import("./containers/jointly-about"), `${titlePrefix}About`)}
        />

        {/* mobile static pages */}
        <PublicRoute
          exact
          path={"/mobile-terms-of-service"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-terms-of-service"), `${titlePrefix}Terms of service`)}
        />
        <PublicRoute
          exact
          path={"/mobile-faq"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-faq"), `${titlePrefix}Mobile FAQ`)}
        />
        <PublicRoute
          exact
          path={"/mobile-user-guide"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-user-guide"), `${titlePrefix}Mobile user guide`)}
        />
        <PublicRoute
          exact
          path={"/mobile-jointly-video"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-video"), `${titlePrefix}Mobile video`)}
        />
        
        <PublicRoute
          exact
          path={"/mobile-jointly-at-glance"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-glance"), `${titlePrefix}Mobile Glance`)}
        />
        <PublicRoute
          exact
          path={"/mobile-privacy-policy"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-privacy-policy"), `${titlePrefix}Mobile private policy`)}
        />
         <PublicRoute
          exact
          path={"/mobile-cookies-policy"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-cookies-policy"), `${titlePrefix}Mobile cookie policy`)}
        />
        <PublicRoute
          exact
          path={"/mobile-about"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/jointly-about"), `${titlePrefix}Mobile about`)}
        />
        <MaintenanceRoute
          exact
          path={"/maintenance"}
          hideToolBar={true}
          component={asyncComponent(() => import("./containers/maintenance"), `${titlePrefix}Maintenance Mode`)}
        />
        <RestrictedRoute
          exact
          path={"/my-account"}
          layoutSettings={{
            title: "My account",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/my-account"), `${titlePrefix}My account`)}
        />
        <RestrictedRoute
          exact
          path={"/create-circle"}
          layoutSettings={{
            title: "Create Circle",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/create/create-circle"), `${titlePrefix}Circle profile`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/edit-circle"}
          layoutSettings={{
            title: "Edit Circle",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/edit-circle"), `${titlePrefix}Circle profile`)}
        />
        <RestrictedRoute
          exact
          path={"/download-informations"}
          layoutSettings={{
            title: "Download Information",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/download-information"), `${titlePrefix}Download Information`)}
        />
        <RestrictedRoute
          exact
          path={"/delete-account"}
          layoutSettings={{
            title: "Delete Account",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/delete-account"), `${titlePrefix}Delete account`)}
        />
        <RestrictedRoute
          exact
          path={"/change-password"}
          layoutSettings={{
            title: "Change password",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/change-password"), `${titlePrefix}Change password`)}
        />
        <RestrictedRoute
          exact
          path={"/search"}
          layoutSettings={{
            title: "Search",
            topbar:true,
            sidebar:false,
            hideSearch:false
          }}
          component={asyncComponent(() => import("./containers/search"), `${titlePrefix}Search`)}
        />
        <RestrictedRoute
          exact
          path={"/dashboard"}
          layoutSettings={{
            title: "Dashboard",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/dashboard"), `${titlePrefix}Dashboard`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/overview"}
          layoutSettings={{
            title: "Overview",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/overview"), `${titlePrefix}Overview`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/circle-details"}
          layoutSettings={{
            title: "Circle Details",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/viewCircle"), `${titlePrefix}Circle Details`)}
        />
        {/* <RestrictedRoute
          exact
          path={"/circle/:id/service"}
          layoutSettings={{
            title: "Services",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/services/list"))}
        /> */}
        <RestrictedRoute
          exact
          path={"/circles"}
          layoutSettings={{
            title: "Create circle",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/create"), `${titlePrefix}Create circle`)}
        />
         <RestrictedRoute
          exact
          path={"/notification-settings"}
          layoutSettings={{
            title: "Notification settings",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/settings"), `${titlePrefix}Notification settings`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id"}
          layoutSettings={{
            title: "Circle post",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/view"), `${titlePrefix}Messages`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/medication"}
          layoutSettings={{
            title: "Circle medications",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/medications/list"), `${titlePrefix}Medications`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/medication/create"}
          layoutSettings={{
            title: "Create medications",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/medications/create"), `${titlePrefix}Medications`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/medication/:medicationid"}
          layoutSettings={{
            title: "View Medications",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/medications/view"), `${titlePrefix}Medications`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/medication/edit/:medicationid"}
          layoutSettings={{
            title: "Edit medications",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/medications/edit"), `${titlePrefix}Medications`)}
        />
        
        <RestrictedRoute
          exact
          path={"/circle/:id/task"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/tasks/list"), `${titlePrefix}Tasks`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/task/:taskid"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/tasks/view"), `${titlePrefix}Tasks`)}
        />
        <RestrictedRoute 
          exact
          path={"/circle/:id/create-task"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/tasks/create"), `${titlePrefix}Tasks`)}
        />
        <RestrictedRoute 
          exact
          path={"/circle/:id/task/edit/:taskid"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/tasks/edit"), `${titlePrefix}Tasks`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/calendar"}
          layoutSettings={{
            title: "Circle calendar",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar"), `${titlePrefix}Calendar`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/calendar/unavailable-date/create"}
          layoutSettings={{
            title: "Create Unavailable Date",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/set-unavailable-date"), `${titlePrefix}Unavailable Date`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/calendar/unavailable-date/edit/:dateId"}
          layoutSettings={{
            title: "Edit Unavailable Date",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/edit-unavailable-date"), `${titlePrefix}Unavailable Date`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/calendar/unavailable-date"}
          layoutSettings={{
            title: "List Unavailable Date",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/list-unavailable-date"), `${titlePrefix}Unavailable Date`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/calendar/event-list-view"}
          layoutSettings={{
            title: "Circle events",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/event-list-view"), `${titlePrefix}Events`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/event/:eventid"}
          layoutSettings={{
            title: "Circle events",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/events"), `${titlePrefix}Events`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/create-event"}
          layoutSettings={{
            title: "Circle events",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/events/create"), `${titlePrefix}Events`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/event/edit/:eventid"}
          layoutSettings={{
            title: "Circle events",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/events/edit"), `${titlePrefix}Events`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/note"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/notes/list"), `${titlePrefix}Notes`)}
        />
        <RestrictedRoute 
          exact
          path={"/circle/:id/note/categories"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/notes/categories"), `${titlePrefix}Notes`)}
        />  
        <RestrictedRoute 
          exact
          path={"/circle/:id/calendar/categories"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/calendar/categories"), `${titlePrefix}Notes`)}
        />  
        <RestrictedRoute 
          exact
          path={"/circle/:id/tasks/categories"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/tasks/categories"), `${titlePrefix}Tasks`)}
        />  
        <RestrictedRoute 
          exact
          path={"/circle/:id/contact/categories"}
          layoutSettings={{
            title: "Circle tasks",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/contacts/categories"), `${titlePrefix}Tasks`)}
        />  
        <RestrictedRoute 
          exact
          path={"/circle/:id/create-note"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/notes/create"), `${titlePrefix}Notes`)}
        />
        <RestrictedRoute 
          exact
          path={"/circle/:id/edit-note/:noteid"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/notes/edit"), `${titlePrefix}Notes`)}
        />
        
        <RestrictedRoute
          exact
          path={"/circle/:id/note/:noteid"}
          layoutSettings={{
            title: "Circle notes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/notes/view"), `${titlePrefix}Notes`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/device"}
          layoutSettings={{
            title: "Circle devices",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/devices/list"), `${titlePrefix}Devices`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/device/:deviceId"}
          layoutSettings={{
            title: "Circle device",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/devices/view"), `${titlePrefix}Devices`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/contact"}
          layoutSettings={{
            title: "Circle contact",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/contacts"), `${titlePrefix}Contacts`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/contact/create"}
          layoutSettings={{
            title: "Add circle contact",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/contacts/create"), `${titlePrefix}Contacts`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/contact/:contactId/edit"}
          layoutSettings={{
            title: "Edit circle contact",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/contacts/edit"), `${titlePrefix}Contacts`)}
        />
        <RestrictedRoute
          exact
          path={"/circle/:id/settings"}
          layoutSettings={{
            title: "Circle Settings",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circles/circleSettings"), `${titlePrefix}Circle Settings`)}
        />
        <AdminRoute 
          exact
          path={"/service-admin/create"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/service-admin/create"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/service-admin/:id/edit"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/service-admin/view"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/password-link"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/account-password-link/create"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/find-circle-by-user"}
          layoutSettings={{
            title: "Find circle by user",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/find-circle-by-user"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/analytics"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/user-analytics"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/analytics/list"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/user/user-analytics/list"), `${titlePrefix}Service Admin`)}
        />
        <AdminRoute 
          exact
          path={"/circle-devices"}
          layoutSettings={{
            title: "Service Admin Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/circleConnected"), `${titlePrefix}Service Admin`)}
        />
        <RestrictedRoute 
          exact
          path={"/partners/create"}
          layoutSettings={{
            title: "Partners Create",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/service-admin/create-circle"), `${titlePrefix}Service Admin`)}
        />
        <RestrictedRoute 
          exact
          path={"/partners"}
          layoutSettings={{
            title: "Partners",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/service-admin/list"), `${titlePrefix}Service Admin`)}
        />

        <AdminRoute 
          exact
          path={"/promo-code/list"}
          layoutSettings={{
            title: "Promo Codes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/promo-code/list"), `${titlePrefix}Promocodes`)}
        />
        <AdminRoute 
          exact
          path={"/promo-code/create"}
          layoutSettings={{
            title: "Promo Codes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/promo-code/create"), `${titlePrefix}Promocodes`)}
        />
        <AdminRoute 
          exact
          path={"/promo-code/:id/edit"}
          layoutSettings={{
            title: "Promo Codes",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/promo-code/edit"), `${titlePrefix}Promocodes`)}
        />
        <AdminRoute 
          exact
          path={"/services"}
          layoutSettings={{
            title: "Services",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/service-admin/list-service"), `${titlePrefix}Services`)}
        /> 
        <AdminRoute 
          exact
          path={"/site-settings"}
          layoutSettings={{
            title: "Site Settings",
            topbar:true,
            sidebar:false
          }}
          component={asyncComponent(() => import("./containers/site-settings"), `${titlePrefix}Site Settings`)}
        /> 

      {maintenanceMode === 'true' ? 
        <Route
          component={asyncComponent(() => import("./containers/maintenance"), `${titlePrefix}Maintenance Mode`)}
        />
        :
        <Route
          component={ asyncComponent(() => import("./containers/not-found") , `${titlePrefix}Not Found`)}
        />
      }
      </Switch>
    </Router>
  );
  }
};
